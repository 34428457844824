export default class CategoryCustomer {
    constructor() {
        this.id = "";
        this.name = "";
        this.description = "";
        this.userId = "";
        this.allowedUsers = [];
        this.shared = false;
        this.showValue = false;
    }
    update(data) {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.allowedUsers = data.listUser;
        this.shared = data.shared;
        this.showValue = data.showValue;
    }
}